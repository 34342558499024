import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlogPost from "./blog-post";
import BlogSidebar from "./blog-sidebar";
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { MenuContext } from "@/context/menu-context";

const isBrowser = typeof window !== "undefined"

const BlogTwo = () => {
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        newsBannerImg
        env {
          APISERVER
          TENANTID
        }
      }
    }
  `)

  const { env } = data.pharmacy
  const { contextTags, setContextTags, contextTagFilter, setcontextTagFilter } = useContext(MenuContext);

  const [news, setNews] = useState([])
  const [categories, setCategories] = useState([])
  const [categorieFilter, setCategorieFilter] = useState(null)
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false)
  const [pageCategory, setPageCategory] = useState('')

  function adaptNews(newsData) {
    let data = []
    newsData.map((news, idx) =>
      data.push({
        pk: news.id,
        img: news.image,
        badge: news.image,
        title: news.title,
        subtitle: news.headline,
        slug: news.slug,
        author: "",
        body: news.content,
        linksTo: "/about",
        detailPage: `/novedades/${news.id}`,
        date: news.date_published,
        tags: news.tags.map(tag => tag.name),
        categorie: news.category.name,
      })
    )
    return data
  }

  useEffect(() => {
    setFetching(true);
    fetch(`${env.APISERVER}/core/noticias/get_data/?tenant=${env.TENANTID}`)
      .then(response => response.json())
      .then(resultData => {
        setNews(adaptNews(resultData['news']))
        setCategories(resultData['categories'])
        setContextTags(resultData['tags'])
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false)
        setError(true)
      })
    
    if (isBrowser) {
      setcontextTagFilter((new URLSearchParams(window?.location.search).get("searchTag")) ? [new URLSearchParams(window?.location.search).get("searchTag")] : contextTagFilter)
      setCategorieFilter(new URLSearchParams(window?.location.search).get("searchCategorie") ?? pageCategory)
    }

  }, [env.APISERVER, env.TENANTID, setContextTags, setcontextTagFilter, pageCategory]); /*eslint-disable-line react-hooks/exhaustive-deps*/


  const posts = categorieFilter ? 
    news?.filter(item => item.categorie === categorieFilter).filter(item => item.tags.some(tag => contextTagFilter.includes(tag)) || contextTagFilter.length === 0).map((newsItem, idx) => {
      const date = new Date(newsItem.date)
      return {
        image: newsItem.img,
        title: newsItem.title,
        text: newsItem.subtitle,
        date: `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`,
        url: newsItem.detailPage,
        tags: newsItem.tags,
        category: newsItem.category

      }
    }  
    )
  :
    news?.filter(item => item.tags.some(tag => contextTagFilter.includes(tag)) || contextTagFilter.length === 0).map((newsItem, idx) => {
    const date = new Date(newsItem.date)
    return {
      image: newsItem.img,
      title: newsItem.title,
      text: newsItem.subtitle,
      date: `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`,
      url: newsItem.detailPage,
      tags: newsItem.tags,
      category: newsItem.category

    }
  }
  )

  if (fetching)
    return (
        <div className="container">
          <div className="row">
            <div
            style={{ marginBottom: "2.5rem", marginTop: "2.5rem", textAlign: "center" }}
            >
              <div className="spinner-border">

              </div>
              <h5>
                <span style={{ color: "grey" }}>Cargando las novedades</span>
              </h5>
            </div>
          </div>
        </div>
    )

  if (error)
    return (
        <div className="container">
          <div className="row">
            <div
              style={{ marginBottom: "2.5rem", textAlign: "center" }}
            >
              <h5>
                <span style={{ color: "grey" }}>
                  Se produjo un error al cargar la lista de novedades.
                </span>
              </h5>
              <Link to="/">
                <span>Volver al inicio</span>
              </Link>
            </div>
          </div>
        </div>

    )
  
  
  return (
    <>
      <Row className="w-100 mx-auto text-center mt-5 mb-3">
        {!!categorieFilter &&
          <h3 className="my-4">Categoría: {categorieFilter} </h3>
        }
      </Row>
      <section className="blog_details_section mb-5 section_padding_25px">
        <Container>
          <Row className={''}>
            <Col lg={8} className='d-flex flex-wrap'>

              <Row>
                {posts.map((post) =>
                  <div key={`post-${post.title}`} className="col-sm-6 desktop-p-3 mb-5">
                    <BlogPost post={post} noTags={true} />
                  </div>
                )}
              </Row>

            </Col>
            <Col lg={4} className={'fixed'}>
              <BlogSidebar news={news} categories={categories} tags={contextTags} setCategory={setPageCategory} />
            </Col>
          </Row>
        </Container>
      </section>
      {!!categorieFilter &&
        <div className="d-flex">
          <a className="showOnMobile mx-auto mb-3 btn-yellow pady-2px" href={`/blog`}>VER TODAS LAS NOVEDADES</a>

        </div>
        }
    </>

  );
};

export default BlogTwo;
